import React from "react"
import SquiglySeperator from "../scss/components/svgs/squigly-seperator"
import LowPlolySky from "../scss/components/svgs/lowpoly-sky"
import Cloud from "../scss/components/svgs/cloud"
import MinecraftBlocks from "../scss/components/svgs/MinecraftBlocks"
import JustHangingMC from "../scss/components/svgs/just-hanging-mc"
import FollowMeSVG from '../scss/components/svgs/follow-me'
import ArrowSVG from '../scss/components/svgs/arrow'

const InitialSection = () => (
  <section className="initial-section">
    <div className="left-side">
      <div className="squigly__seperator">
        <SquiglySeperator height={"100%"} width={undefined} />
      </div>

      <div className="svg__wrapper aspectRatioSizer">
        <div className="low__poly__sky-svg ">
          <LowPlolySky height={'100%'} width={"100%"} viewBox="0 0 512 175"/>
        </div>
        <div className="">
          <JustHangingMC height={'auto'} width={'100%'} preserveAspectRatio="xMinYMin none" />
        </div>
        <div className="">
          <MinecraftBlocks height={'100%'} width={'100%'} preserveAspectRatio="xMinYMin" viewBox="0 100 413 177"/>
        </div>
      </div>
    </div>
    <div className="right-side">
      <div className="content">
        <div className="cloud">
          <Cloud />
        </div>
        <h1>
          Dont Spend Your Time Managing <br /> A Server
        </h1>
        <p>
          We provide fast, secure, and affordable, virtual private minecraft game servers.
          Easily manage your own virtual private minecraft game server. Includes
          FTP, 99.999% uptime, and one-click mods like forest. Get started in
          minutes!
        </p>
        <div className="action">
          <div className="follow__me-wrapper">
            <FollowMeSVG height={'100%'} width={'100%'} preserveAspectRatio="xMinYMin" />
          </div>
          <h3 className="hoverable">Check out what makes us different</h3>
          <div className="arrow__transform bounce hoverable">
            <ArrowSVG />
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default InitialSection
