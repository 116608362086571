import React from "react"

const MinecraftBlocks = props => (
  <svg
    width={521}
    height={372}
    viewBox="0 0 521 372"
    fill="none"
    {...props}
  >
    <path
      transform="rotate(-90 1 210)"
      fill="#1B409C"
      fillOpacity={0.35}
      d="M1 210H211V284H1z"
    />
    <path d="M311 246h210v126H311V246z" fill="#35AA30" fillOpacity={0.2} />
    <path fill="#4A3838" d="M146 168H165V233H146z" />
    <path fill="#604848" d="M115 168H155V233H115z" />
    <path
      transform="matrix(-1 0 0 1 166 81)"
      fill="#69AA7B"
      d="M0 0H50V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 135 139)"
      fill="#69AA7B"
      d="M0 0H50V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 138 81)"
      fill="#69AA7B"
      d="M0 0H32V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 183 110)"
      fill="#578E67"
      d="M0 0H35V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 203 139)"
      fill="#578E67"
      d="M0 0H35V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 133 110)"
      fill="#578E67"
      d="M0 0H35V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 165 110)"
      fill="#69AA7B"
      d="M0 0H50V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 182 139)"
      fill="#69AA7B"
      d="M0 0H50V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 166 81)"
      fill="#578E67"
      d="M0 0H28V26H0z"
    />
    <g filter="url(#filter0_f)">
      <path fill="#000" fillOpacity={0.25} d="M115 168H165V184H115z" />
    </g>
    <path fill="#6F5353" d="M208 249H311V279H208z" />
    <path fill="#52A96B" d="M0 233H208V272H0z" />
    <path fill="#52A96B" d="M-3 262H205V301H-3z" />
    <path fill="#52A96B" d="M208 233H479V249H208z" />
    <path fill="#6F5353" d="M141 271H208V301H141z" />
    <path fill="#6F5353" d="M208 271H311V301H208z" />
    <path fill="#6F5353" d="M76 271H143V301H76z" />
    <defs>
      <filter
        id="filter0_f"
        x={111}
        y={164}
        width={58}
        height={24}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation={2} result="effect1_foregroundBlur" />
      </filter>
    </defs>
  </svg>
)

export default MinecraftBlocks
