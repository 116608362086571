import React from 'react'
import LighthouseSVG from '../scss/components/svgs/lighthouse'
import ComputerGuySVG from '../scss/components/svgs/computer-guy'

const PricingSection = () => {

  return (
    <section className="pricing-section">
      <div className="all__plans__include-box all__plans-display">
        <div>
          <h2>All plans inlude</h2>
        </div>
        <div>
          <p>99.99% Uptime</p>
          <p>99.99% Uptime</p>
          <p>99.99% Uptime</p>
        </div>
        <div>
          <p>99.99% Uptime</p>
          <p>99.99% Uptime</p>
          <p>99.99% Uptime</p>
        </div>
        <div className="svg__wrapper-computer__guy">
          <ComputerGuySVG />
        </div>
      </div>
      <div className="centered">
        <div className="bottom__margin-limited action__text">
          <h4>Pricing</h4>
          <h2>Plans for all your needs!</h2>
        </div>
        <div className="action-pricing action__text">
          <div className="item">
            <h3>$6</h3>
            <p>1GB ram</p>
            <p>25GB SSD</p>
          </div>
          <div className="item">
            <h3>$11</h3>
            <p>1GB ram</p>
            <p>25GB SSD</p>
          </div>
          <div className="item">
            <h3>$21</h3>
            <p>1GB ram</p>
            <p>25GB SSD</p>
          </div>
          <div className="item">
            <h3>$51</h3>
            <p>1GB ram</p>
            <p>25GB SSD</p>
          </div>
          <div className="item">
            <h3>$101</h3>
            <p>1GB ram</p>
            <p>25GB SSD</p>
          </div>
        </div>
      </div>
      <div className="powered__by">
        <h4>Powered by Digital Ocean</h4>
      </div>
      <div className="lighouse__svg">
        <LighthouseSVG />
      </div>
    </section>
  )
}

export default PricingSection