import React from "react";

const ComputerGuySVG = props => (
  <svg width={119} height={137} viewBox="0 0 119 137" fill="none" {...props}>
    <path
      d="M61.459 50.276H59.76v1.135h1.698v-1.135zM63.721 50.276h-1.697v1.135h1.697v-1.135zM65.986 50.276H64.29v1.135h1.697v-1.135zM68.249 50.276H66.55v1.135h1.698v-1.135zM70.513 50.276h-1.698v1.135h1.698v-1.135zM72.778 50.276H71.08v1.135h1.698v-1.135zM75.04 50.276h-1.697v1.135h1.697v-1.135zM77.305 50.276h-1.698v1.135h1.698v-1.135zM79.568 50.276h-1.697v1.135h1.697v-1.135zM81.832 50.276h-1.697v1.135h1.697v-1.135z"
      fill="#B0BEC5"
    />
    <path
      d="M119 51.064H45.962v81.677H119V51.064z"
      fill="url(#paint0_linear)"
    />
    <path d="M117.794 105.596H47.169v25.367h70.625v-25.367z" fill="#ECEFF1" />
    <path d="M55.702 111.614h-2.736v12.997h2.736v-12.997z" fill="#64FFDA" />
    <path
      opacity={0.7}
      d="M61.105 111.614H58.37v12.997h2.736v-12.997z"
      fill="#64FFDA"
    />
    <path
      opacity={0.6}
      d="M66.508 111.614h-2.736v12.997h2.736v-12.997z"
      fill="#64FFDA"
    />
    <path
      opacity={0.5}
      d="M71.911 111.614h-2.736v12.997h2.736v-12.997zM77.314 111.614h-2.736v12.997h2.736v-12.997z"
      fill="#64FFDA"
    />
    <path
      opacity={0.4}
      d="M82.717 111.614H79.98v12.997h2.736v-12.997z"
      fill="#64FFDA"
    />
    <path
      opacity={0.3}
      d="M88.118 111.614h-2.736v12.997h2.736v-12.997z"
      fill="#64FFDA"
    />
    <path
      d="M107.794 124.645c3.647 0 6.603-2.962 6.603-6.616 0-3.655-2.956-6.617-6.603-6.617s-6.604 2.962-6.604 6.617c0 3.654 2.957 6.616 6.604 6.616z"
      fill="#6C63FF"
    />
    <path d="M117.794 79.242H47.169v25.367h70.625V79.242z" fill="#ECEFF1" />
    <path d="M55.702 85.26h-2.736v12.997h2.736V85.26z" fill="#64FFDA" />
    <path
      opacity={0.7}
      d="M61.105 85.26H58.37v12.997h2.736V85.26z"
      fill="#64FFDA"
    />
    <path
      opacity={0.6}
      d="M66.508 85.26h-2.736v12.997h2.736V85.26z"
      fill="#64FFDA"
    />
    <path
      opacity={0.5}
      d="M71.911 85.26h-2.736v12.997h2.736V85.26zM77.314 85.26h-2.736v12.997h2.736V85.26z"
      fill="#64FFDA"
    />
    <path
      opacity={0.4}
      d="M82.717 85.26H79.98v12.997h2.736V85.26z"
      fill="#64FFDA"
    />
    <path
      opacity={0.3}
      d="M88.118 85.26h-2.736v12.997h2.736V85.26z"
      fill="#64FFDA"
    />
    <path
      d="M107.794 98.293c3.647 0 6.603-2.962 6.603-6.616 0-3.654-2.956-6.617-6.603-6.617s-6.604 2.963-6.604 6.617 2.957 6.616 6.604 6.616z"
      fill="#6C63FF"
    />
    <path d="M117.794 52.89H47.169v25.367h70.625V52.891z" fill="#ECEFF1" />
    <path d="M55.702 58.906h-2.736v12.997h2.736V58.906z" fill="#64FFDA" />
    <path
      opacity={0.7}
      d="M61.105 58.906H58.37v12.997h2.736V58.906z"
      fill="#64FFDA"
    />
    <path
      opacity={0.6}
      d="M66.508 58.906h-2.736v12.997h2.736V58.906z"
      fill="#64FFDA"
    />
    <path
      opacity={0.5}
      d="M71.911 58.906h-2.736v12.997h2.736V58.906zM77.314 58.906h-2.736v12.997h2.736V58.906z"
      fill="#64FFDA"
    />
    <path
      opacity={0.4}
      d="M82.717 58.906H79.98v12.997h2.736V58.906z"
      fill="#64FFDA"
    />
    <path
      opacity={0.3}
      d="M88.118 58.906h-2.736v12.997h2.736V58.906z"
      fill="#64FFDA"
    />
    <path
      d="M107.794 72.455c3.932 0 7.119-3.193 7.119-7.132 0-3.939-3.187-7.132-7.119-7.132-3.931 0-7.118 3.193-7.118 7.132 0 3.94 3.187 7.132 7.118 7.132z"
      fill="url(#paint1_linear)"
    />
    <path
      d="M107.794 98.985c3.932 0 7.119-3.193 7.119-7.132 0-3.94-3.187-7.132-7.119-7.132-3.931 0-7.118 3.193-7.118 7.132 0 3.939 3.187 7.132 7.118 7.132z"
      fill="url(#paint2_linear)"
    />
    <path
      d="M107.794 125.337c3.932 0 7.119-3.193 7.119-7.132 0-3.939-3.187-7.133-7.119-7.133-3.931 0-7.118 3.194-7.118 7.133s3.187 7.132 7.118 7.132z"
      fill="url(#paint3_linear)"
    />
    <path
      d="M107.794 71.94c3.647 0 6.603-2.963 6.603-6.617s-2.956-6.616-6.603-6.616-6.604 2.962-6.604 6.616c0 3.654 2.957 6.616 6.604 6.616z"
      fill="#6C63FF"
    />
    <path
      d="M84.662 54.057H58.58a.896.896 0 01-.897-.899v-1.423a.9.9 0 01.897-.9h26.083v3.222z"
      fill="url(#paint4_linear)"
    />
    <path
      d="M84.662 53.678H59.05a.896.896 0 01-.897-.899v-.858a.9.9 0 01.898-.898h25.611v2.655z"
      fill="#B0BEC5"
    />
    <path
      d="M18.194 36.537l18.84 16.699 21.893-4.633-1.696-4.943-16.964 2.007-16.974-14.923a3.856 3.856 0 00-2.995-.933 3.85 3.85 0 00-2.697 1.605 3.87 3.87 0 00.593 5.121z"
      fill="url(#paint5_linear)"
    />
    <path
      d="M58.916 48.603s5.584.154 7.648-2.934c2.063-3.09-9.344-2.009-9.344-2.009l1.696 4.943z"
      fill="url(#paint6_linear)"
    />
    <path
      d="M15.723 25.652c5.363 0 9.71-4.356 9.71-9.73 0-5.374-4.347-9.73-9.71-9.73-5.364 0-9.712 4.356-9.712 9.73 0 5.374 4.348 9.73 9.712 9.73z"
      fill="url(#paint7_linear)"
    />
    <path
      d="M11.716 26.578S-.755 34.993 2.412 61.005c.386 3.142.113 6.33-.8 9.361l-1.615 5.322s27.436.618 28.206 0c.477-.383.106-10.185-1.233-23.783-.82-8.33 1.752-19.917-7.09-26.249l-8.164.922z"
      fill="url(#paint8_linear)"
    />
    <path
      d="M28.206 75.38l12.177 25.791 1.387 26.253h-8.63l-3.7-27.18-7.4-13.127-7.398 40.307h-8.63l2.62-29.96L0 75.69l28.206-.31z"
      fill="url(#paint9_linear)"
    />
    <path
      d="M41.772 127.424l2.62 4.015s8.792 3.398 4.932 5.559h-16.8v-9.574h9.248z"
      fill="url(#paint10_linear)"
    />
    <path
      d="M14.49 127.424l2.62 4.015s8.793 3.398 4.933 5.559H5.242v-9.574h9.248z"
      fill="url(#paint11_linear)"
    />
    <path
      opacity={0.2}
      d="M15.877 6.04a9.648 9.648 0 00-4.784 1.258 9.692 9.692 0 017.198-.223 9.716 9.716 0 015.371 4.806 9.746 9.746 0 01.59 7.19 9.725 9.725 0 01-4.515 5.621 9.719 9.719 0 004.759-4.445 9.747 9.747 0 00-2.489-12.03 9.7 9.7 0 00-6.13-2.183v.005z"
      fill="url(#paint12_linear)"
    />
    <path
      d="M27.528 0l-2.702 4.135c-.971 1.487-2.382 3.175-4.128 2.83-.81-.16-1.46-.746-2.108-1.258-2.12-1.687-4.74-2.83-7.447-2.847-2.706-.016-5.475 1.205-6.998 3.443-1.129 1.653-1.518 3.692-1.74 5.68-.447 3.977 0 8.671 3.314 10.904.267.214.598.333.94.34.359-.023.65-.252.919-.478 4.148-3.483 6.46-9.146 11.455-11.537 2.206-1.054 5.414-1.063 7.192-2.854 2.62-2.632-.28-5.936 1.303-8.358z"
      fill="url(#paint13_linear)"
    />
    <g opacity={0.2}>
      <path
        opacity={0.2}
        d="M17.512 5.707c.647.516 1.295 1.102 2.108 1.258.33.066.67.059.998-.02-.774-.18-1.4-.746-2.026-1.242-2.12-1.686-4.741-2.83-7.447-2.846-.19 0-.379 0-.569.016 2.53.15 4.962 1.262 6.936 2.834z"
        fill="url(#paint14_linear)"
      />
      <path
        opacity={0.2}
        d="M27.528 0l-1.56 2.39c.15 1.965 1.011 4.135-.816 5.968-1.78 1.798-4.988 1.798-7.192 2.854-5 2.39-7.308 8.056-11.455 11.537a2.96 2.96 0 01-.46.33c.19.104.404.155.62.148.358-.023.65-.252.918-.478 4.149-3.483 6.46-9.146 11.456-11.537 2.205-1.054 5.413-1.063 7.192-2.854 2.614-2.632-.286-5.936 1.297-8.358z"
        fill="url(#paint15_linear)"
      />
    </g>
    <path
      opacity={0.2}
      d="M26.976 51.907c-.82-8.33 1.75-19.917-7.09-26.25l-1.872.213c8.497 6.379 5.994 17.8 6.805 26.04 1.338 13.598 1.711 23.4 1.232 23.783-.235.18-2.95.263-6.608.274 4.706.012 8.487-.049 8.767-.274.476-.386.102-10.188-1.235-23.786z"
      fill="url(#paint16_linear)"
    />
    <path
      d="M12.764 39.84l18.841 16.699 21.892-4.634-1.712-4.94-16.955 2.008-16.974-14.922a3.852 3.852 0 00-5.692.672 3.869 3.869 0 00.6 5.117z"
      fill="url(#paint17_linear)"
    />
    <path
      d="M53.486 51.907s5.584.155 7.648-2.934c2.063-3.089-9.35-2.008-9.35-2.008l1.702 4.942z"
      fill="url(#paint18_linear)"
    />
    <path
      d="M16.209 21.483h-.976a3.677 3.677 0 00-3.673 3.68v.979a3.677 3.677 0 003.673 3.68h.976a3.677 3.677 0 003.673-3.68v-.979a3.677 3.677 0 00-3.673-3.68z"
      fill="url(#paint19_linear)"
    />
    <g opacity={0.2}>
      <path
        opacity={0.2}
        d="M20.345 87.117l-7.398 40.307h1.696l6.982-38.038-1.28-2.269z"
        fill="url(#paint20_linear)"
      />
      <path
        opacity={0.2}
        d="M40.383 101.171l-12.177-25.79-1.686.017 12.167 25.773 1.388 26.253h1.695l-1.387-26.253z"
        fill="url(#paint21_linear)"
      />
    </g>
    <path
      opacity={0.2}
      d="M44.392 131.441l-2.62-4.015h-.771l2.62 4.015s8.787 3.401 4.934 5.559h.77c3.852-2.158-4.933-5.559-4.933-5.559z"
      fill="url(#paint22_linear)"
    />
    <path
      opacity={0.2}
      d="M17.881 131.441l-2.62-4.015h-.77l2.62 4.015s8.793 3.398 4.933 5.559h.77c3.85-2.158-4.933-5.559-4.933-5.559z"
      fill="url(#paint23_linear)"
    />
    <path
      opacity={0.2}
      d="M54.584 47.767l-.328-.957-15.728 1.863 16.056-.906z"
      fill="url(#paint24_linear)"
    />
    <path
      d="M7.747 39.658l5.238-9.912 8.727 3.305-8.727 12.632-5.238-6.025z"
      fill="url(#paint25_linear)"
    />
    <path
      d="M18.612 37.413L36.93 53.654l21.287-4.506-1.65-4.806-16.49 1.953-16.508-14.52a3.747 3.747 0 00-6.198 3.256 3.76 3.76 0 001.24 2.38zM58.218 49.147s5.432.151 7.438-2.853c2.006-3.004-9.087-1.952-9.087-1.952l1.649 4.805zM16.207 26.826c5.216 0 9.444-4.236 9.444-9.462s-4.228-9.462-9.444-9.462c-5.216 0-9.444 4.236-9.444 9.462s4.228 9.462 9.444 9.462z"
      fill="#CA7070"
    />
    <path
      d="M12.308 27.727s-12.13 8.184-9.05 33.484a22.19 22.19 0 01-.778 9.107L.915 75.494s26.685.6 27.435 0c.464-.372.102-9.907-1.199-23.132-.797-8.101 1.703-19.372-6.896-25.53l-7.947.895z"
      fill="#263238"
    />
    <path
      d="M28.35 75.192l11.843 25.084 1.348 25.534h-8.395l-3.598-26.435-7.195-12.768-7.196 39.203H6.761L9.31 96.671.915 75.492l27.435-.3z"
      fill="#5CAADE"
    />
    <path
      d="M41.542 125.81l2.548 3.905s8.545 3.304 4.797 5.408h-16.34v-9.313h8.995zM15 125.81l2.548 3.905s8.545 3.304 4.796 5.408H6.014v-9.313H15z"
      fill="#263238"
    />
    <path
      opacity={0.2}
      d="M16.358 7.75a9.383 9.383 0 00-4.653 1.23 9.427 9.427 0 016.965-.172 9.45 9.45 0 015.188 4.66 9.479 9.479 0 01.587 6.955 9.46 9.46 0 01-4.333 5.467 9.452 9.452 0 004.625-4.325 9.48 9.48 0 00-2.42-11.695 9.434 9.434 0 00-5.959-2.126v.007z"
      fill="#fff"
    />
    <path
      d="M27.688 1.877L25.06 5.903c-.944 1.438-2.315 3.087-4.007 2.752-.79-.156-1.42-.72-2.05-1.228-2.063-1.64-4.611-2.752-7.235-2.769-2.623-.016-5.324 1.173-6.818 3.348-1.097 1.606-1.475 3.596-1.694 5.525-.435 3.867 0 8.434 3.23 10.608.26.207.58.322.913.329.34-.024.632-.245.897-.466 4.034-3.386 6.28-8.896 11.143-11.22 2.145-1.025 5.265-1.034 6.999-2.775 2.532-2.56-.29-5.775 1.249-8.13z"
      fill="#263238"
    />
    <g opacity={0.2} fill="#fff">
      <path
        opacity={0.2}
        d="M17.953 7.427c.63.502 1.256 1.079 2.05 1.228.32.064.651.058.97-.018-.752-.18-1.362-.72-1.974-1.208-2.058-1.642-4.606-2.754-7.23-2.77-.179 0-.367 0-.552.016 2.446.142 4.805 1.215 6.736 2.752zM27.688 1.877L26.17 4.202c.143 1.91.983 4.016-.795 5.805-1.732 1.74-4.845 1.75-6.998 2.774-4.865 2.325-7.108 7.835-11.144 11.221-.138.121-.287.23-.446.322.184.1.391.15.6.144.342-.024.632-.245.898-.466 4.034-3.386 6.28-8.896 11.143-11.22 2.145-1.025 5.265-1.034 6.998-2.775 2.545-2.56-.276-5.775 1.262-8.13z"
      />
    </g>
    <path
      opacity={0.2}
      d="M27.151 52.36c-.796-8.101 1.703-19.372-6.895-25.53l-1.82.207c8.263 6.204 5.828 17.315 6.618 25.329 1.3 13.225 1.663 22.76 1.199 23.131-.228.18-2.872.256-6.428.266 4.58.013 8.254-.048 8.527-.266.465-.377.106-9.91-1.2-23.137z"
      fill="#fff"
    />
    <path
      d="M13.33 40.633l18.318 16.235 21.287-4.506-1.649-4.806-16.49 1.953-16.509-14.52a3.75 3.75 0 00-4.39-.406 3.763 3.763 0 00-.568 6.05zM52.935 52.36s5.432.151 7.438-2.853c2.006-3.004-9.087-1.953-9.087-1.953l1.65 4.806zM16.58 22.77h-.748a3.677 3.677 0 00-3.673 3.68v.75a3.677 3.677 0 003.673 3.68h.749a3.677 3.677 0 003.673-3.68v-.75a3.677 3.677 0 00-3.673-3.68z"
      fill="#CA7070"
    />
    <g opacity={0.2} fill="#fff">
      <path
        opacity={0.2}
        d="M20.704 86.607l-7.196 39.203h1.65l6.79-36.996-1.244-2.207zM40.193 100.276L28.35 75.192l-1.642.018 11.836 25.066 1.348 25.534h1.649l-1.348-25.534z"
      />
    </g>
    <path
      opacity={0.2}
      d="M44.09 129.717l-2.548-3.905h-.75l2.548 3.905s8.545 3.304 4.797 5.408h.75c3.749-2.104-4.796-5.408-4.796-5.408zM18.307 129.717l-2.548-3.905h-.76l2.549 3.905s8.545 3.304 4.796 5.408h.75c3.749-2.104-4.787-5.408-4.787-5.408zM54.003 48.335l-.318-.93-15.297 1.81 15.615-.88z"
      fill="#fff"
    />
    <path
      d="M8.45 40.447l5.092-9.638 8.49 3.213-8.49 12.285-5.092-5.86z"
      fill="#263238"
    />
    <path
      d="M82.871 51.305L98.303 30.28a.898.898 0 011.256-.19l.718.53a.898.898 0 01.19 1.259L85.035 52.903l-2.164-1.598z"
      fill="url(#paint26_linear)"
    />
    <path
      d="M83.178 51.53l15.17-20.676a.898.898 0 011.256-.19l.074.053a.899.899 0 01.19 1.259L84.698 52.65l-1.52-1.121z"
      fill="#B0BEC5"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1={82.48}
        y1={132.743}
        x2={82.48}
        y2={51.064}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="gray" stopOpacity={0.25} />
        <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
        <stop offset={1} stopColor="gray" stopOpacity={0.1} />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1={8653.05}
        y1={5806.8}
        x2={8653.05}
        y2={4675.05}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="gray" stopOpacity={0.25} />
        <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
        <stop offset={1} stopColor="gray" stopOpacity={0.1} />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1={8653.05}
        y1={7938.21}
        x2={8653.05}
        y2={6806.46}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="gray" stopOpacity={0.25} />
        <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
        <stop offset={1} stopColor="gray" stopOpacity={0.1} />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1={8653.05}
        y1={10055.3}
        x2={8653.05}
        y2={8923.56}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="gray" stopOpacity={0.25} />
        <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
        <stop offset={1} stopColor="gray" stopOpacity={0.1} />
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1={18001.5}
        y1={1241.85}
        x2={18001.5}
        y2={1184.25}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="gray" stopOpacity={0.25} />
        <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
        <stop offset={1} stopColor="gray" stopOpacity={0.1} />
      </linearGradient>
      <linearGradient
        id="paint5_linear"
        x1={20177.4}
        y1={8591.97}
        x2={20177.4}
        y2={5532.24}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="gray" stopOpacity={0.25} />
        <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
        <stop offset={1} stopColor="gray" stopOpacity={0.1} />
      </linearGradient>
      <linearGradient
        id="paint6_linear"
        x1={5947.61}
        y1={1783.21}
        x2={5947.61}
        y2={1636.99}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="gray" stopOpacity={0.25} />
        <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
        <stop offset={1} stopColor="gray" stopOpacity={0.1} />
      </linearGradient>
      <linearGradient
        id="paint7_linear"
        x1={1707.83}
        y1={2782.81}
        x2={1707.83}
        y2={676.605}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="gray" stopOpacity={0.25} />
        <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
        <stop offset={1} stopColor="gray" stopOpacity={0.1} />
      </linearGradient>
      <linearGradient
        id="paint8_linear"
        x1={9872.76}
        y1={24752}
        x2={9872.76}
        y2={10674.6}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="gray" stopOpacity={0.25} />
        <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
        <stop offset={1} stopColor="gray" stopOpacity={0.1} />
      </linearGradient>
      <linearGradient
        id="paint9_linear"
        x1={4861.65}
        y1={36960.9}
        x2={4861.65}
        y2={21895.8}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="gray" stopOpacity={0.25} />
        <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
        <stop offset={1} stopColor="gray" stopOpacity={0.1} />
      </linearGradient>
      <linearGradient
        id="paint10_linear"
        x1={8908.61}
        y1={8083.27}
        x2={8908.61}
        y2={7573.37}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="gray" stopOpacity={0.25} />
        <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
        <stop offset={1} stopColor="gray" stopOpacity={0.1} />
      </linearGradient>
      <linearGradient
        id="paint11_linear"
        x1={6177.96}
        y1={8083.27}
        x2={6177.96}
        y2={7573.37}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="gray" stopOpacity={0.25} />
        <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
        <stop offset={1} stopColor="gray" stopOpacity={0.1} />
      </linearGradient>
      <linearGradient
        id="paint12_linear"
        x1={5383.05}
        y1={3856.61}
        x2={5383.05}
        y2={1920.22}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="gray" stopOpacity={0.25} />
        <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
        <stop offset={1} stopColor="gray" stopOpacity={0.1} />
      </linearGradient>
      <linearGradient
        id="paint13_linear"
        x1={8882.18}
        y1={4603.61}
        x2={8882.18}
        y2={1602.67}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="gray" stopOpacity={0.25} />
        <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
        <stop offset={1} stopColor="gray" stopOpacity={0.1} />
      </linearGradient>
      <linearGradient
        id="paint14_linear"
        x1={3578.93}
        y1={451.406}
        x2={3578.93}
        y2={355.475}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="gray" stopOpacity={0.25} />
        <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
        <stop offset={1} stopColor="gray" stopOpacity={0.1} />
      </linearGradient>
      <linearGradient
        id="paint15_linear"
        x1={7781.71}
        y1={4603.74}
        x2={7781.71}
        y2={1602.72}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="gray" stopOpacity={0.25} />
        <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
        <stop offset={1} stopColor="gray" stopOpacity={0.1} />
      </linearGradient>
      <linearGradient
        id="paint16_linear"
        x1={4151.91}
        y1={24753.7}
        x2={4151.91}
        y2={10675.4}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="gray" stopOpacity={0.25} />
        <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
        <stop offset={1} stopColor="gray" stopOpacity={0.1} />
      </linearGradient>
      <linearGradient
        id="paint17_linear"
        x1={18900.9}
        y1={9024.7}
        x2={18900.9}
        y2={5965.67}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="gray" stopOpacity={0.25} />
        <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
        <stop offset={1} stopColor="gray" stopOpacity={0.1} />
      </linearGradient>
      <linearGradient
        id="paint18_linear"
        x1={5654.96}
        y1={1880.69}
        x2={5654.96}
        y2={1734.53}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="gray" stopOpacity={0.25} />
        <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
        <stop offset={1} stopColor="gray" stopOpacity={0.1} />
      </linearGradient>
      <linearGradient
        id="paint19_linear"
        x1={740.775}
        y1={1404.62}
        x2={740.775}
        y2={1017.87}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="gray" stopOpacity={0.25} />
        <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
        <stop offset={1} stopColor="gray" stopOpacity={0.1} />
      </linearGradient>
      <linearGradient
        id="paint20_linear"
        x1={848.876}
        y1={28654.4}
        x2={848.876}
        y2={19617.9}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="gray" stopOpacity={0.25} />
        <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
        <stop offset={1} stopColor="gray" stopOpacity={0.1} />
      </linearGradient>
      <linearGradient
        id="paint21_linear"
        x1={2928.58}
        y1={36960.9}
        x2={2928.58}
        y2={21895.8}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="gray" stopOpacity={0.25} />
        <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
        <stop offset={1} stopColor="gray" stopOpacity={0.1} />
      </linearGradient>
      <linearGradient
        id="paint22_linear"
        x1={4903.85}
        y1={8083.27}
        x2={4903.85}
        y2={7573.37}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="gray" stopOpacity={0.25} />
        <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
        <stop offset={1} stopColor="gray" stopOpacity={0.1} />
      </linearGradient>
      <linearGradient
        id="paint23_linear"
        x1={3501.93}
        y1={8083.27}
        x2={3501.93}
        y2={7573.37}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="gray" stopOpacity={0.25} />
        <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
        <stop offset={1} stopColor="gray" stopOpacity={0.1} />
      </linearGradient>
      <linearGradient
        id="paint24_linear"
        x1={4204.36}
        y1={551.06}
        x2={4204.36}
        y2={531.763}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="gray" stopOpacity={0.25} />
        <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
        <stop offset={1} stopColor="gray" stopOpacity={0.1} />
      </linearGradient>
      <linearGradient
        id="paint25_linear"
        x1={1154.07}
        y1={4079.07}
        x2={1154.07}
        y2={2666.45}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="gray" stopOpacity={0.25} />
        <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
        <stop offset={1} stopColor="gray" stopOpacity={0.1} />
      </linearGradient>
      <linearGradient
        id="paint26_linear"
        x1={13939.7}
        y1={8379.7}
        x2={13939.7}
        y2={5441.41}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="gray" stopOpacity={0.25} />
        <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
        <stop offset={1} stopColor="gray" stopOpacity={0.1} />
      </linearGradient>
      <clipPath id="clip0" />
    </defs>
  </svg>
);

export default ComputerGuySVG;
