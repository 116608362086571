import React from "react"

const LowPlolySky = props => (
  <svg width={493} height={158} viewBox="0 0 493 158" fill="none" preserveAspectRatio="xMidYMid meet" {...props}>
    <path
      d="M311.649 36L250 45.455 311.649 52 380 45.455 311.649 36zM419.969 91L355 98.09l64.969 4.91L492 98.09 419.969 91zM244.969 142L180 149.091 244.969 154 317 149.091 244.969 142zM87 14L18 27l69 9 76.5-9L87 14z"
      fill="#fff"
      fillOpacity={0.11}
      stroke="#FFFEFE"
      strokeOpacity={0.1}
    />
    <ellipse cx={89} cy={86} rx={7} ry={5} fill="#fff" fillOpacity={0.35} />
    <ellipse cx={359} cy={3} rx={4} ry={3} fill="#fff" fillOpacity={0.35} />
    <ellipse cx={7} cy={47} rx={7} ry={5} fill="#fff" fillOpacity={0.35} />
    <ellipse cx={157} cy={108} rx={7} ry={5} fill="#fff" fillOpacity={0.1} />
    <ellipse cx={84} cy={153} rx={7} ry={5} fill="#fff" fillOpacity={0.1} />
    <ellipse cx={201} cy={42} rx={7} ry={5} fill="#fff" fillOpacity={0.05} />
  </svg>
)

export default LowPlolySky
