import React from "react"

const Cloud = props => (
  <svg width={110} height={110} viewBox="0 0 110 110" fill="none" {...props}>
    <path
      d="M88.795 42.838H84.3A33.643 33.643 0 0073.817 23.09a33.638 33.638 0 00-22.885-8.947 33.634 33.634 0 00-22.91 8.97 33.636 33.636 0 00-10.505 20.051 21.146 21.146 0 00-11.869 6.474A21.124 21.124 0 000 64.043c0 11.692 9.513 21.205 21.205 21.205 3.578 0 7.017-.876 10.1-2.555 3.208 7.889 10.875 13.164 19.627 13.164 7.679 0 14.589-4.028 18.372-10.609h19.491c11.693 0 21.205-9.513 21.205-21.205 0-11.692-9.513-21.205-21.205-21.205zm0 37.682H67.883c-.905 0-1.73.517-2.126 1.33-2.785 5.723-8.466 9.279-14.825 9.279a16.413 16.413 0 01-16.023-12.64 2.364 2.364 0 00-3.732-1.332 16.324 16.324 0 01-9.972 3.363c-9.086 0-16.478-7.392-16.478-16.478 0-8.516 6.643-15.727 15.124-16.416a2.364 2.364 0 002.164-2.162 28.916 28.916 0 019.216-18.88 28.923 28.923 0 0119.7-7.713 28.925 28.925 0 0119.679 7.692c5.341 4.933 8.62 11.623 9.232 18.838a2.364 2.364 0 002.356 2.164h6.597c9.086 0 16.477 7.392 16.477 16.477 0 9.086-7.391 16.478-16.477 16.478z"
      fill="#fff"
      fillOpacity={0.35}
    />
    <path
      d="M21.205 71.065c-3.872 0-7.022-3.15-7.022-7.022a2.364 2.364 0 10-4.727 0c0 6.478 5.27 11.749 11.749 11.749a2.364 2.364 0 100-4.727zM67.402 30.037a24.208 24.208 0 00-16.47-6.438 2.363 2.363 0 100 4.727c4.932 0 9.643 1.841 13.263 5.184a19.485 19.485 0 016.226 12.69 2.364 2.364 0 004.71-.398 24.208 24.208 0 00-7.729-15.765z"
      fill="#fff"
      fillOpacity={0.25}
    />
  </svg>
)

export default Cloud
