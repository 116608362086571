import React from "react";

const LighthouseSVG = props => (
  <svg width={470} height={355} viewBox="0 0 470 355" fill="none" {...props}>
  <path
    d="M286.173 204.812l5.038 17.453 5.039 17.454h-20.155l5.039-17.454 5.039-17.453z"
    fill="#3AD29F"
  />
  <path
    d="M296.094 202.448l5.034 17.453 5.038 17.458h-20.149l5.038-17.458 5.039-17.453z"
    fill="#3AD29F"
  />
  <path
    d="M306.011 202.448l5.039 17.453 5.038 17.458h-20.154l5.038-17.458 5.039-17.453zM382.621 213.104l5.039 17.454 5.038 17.458h-20.155l5.039-17.458 5.039-17.454zM60.35 195.932l8.626 15.988 8.627 15.987-9.841 2.139-9.847 2.143 1.215-18.131 1.22-18.126z"
    fill="#3AD29F"
  />
  <path
    d="M0 354.935l19.07-89.852 14.053-18.573 14.052-24.094 26.097-15.561 19.573-11.043 8.03 24.094 15.427 10.331 9.666 14.265 15.056-5.019 19.07-15.561 14.554-4.016 20.577-13.051 19.071 5.02 19.572-7.028 13.049-9.035 12.546 15.059 7.026 19.576 1.506 7.028H306.137l12.546-13.553 19.573-4.016h19.071l13.048 4.016 23.086 6.525 51.19 124.488H0z"
    fill="#E0E0E0"
  />
  <g opacity={0.3} fill="#E0E0E0">
    <path
      opacity={0.3}
      d="M142.991 89.741H80.639a7.559 7.559 0 00-7.558 7.56 7.56 7.56 0 007.558 7.56h62.352a7.56 7.56 0 007.558-7.56 7.559 7.559 0 00-7.558-7.56z"
    />
    <path
      opacity={0.3}
      d="M124.096 104.86H61.744a7.56 7.56 0 00-7.558 7.56 7.56 7.56 0 007.558 7.56h62.352a7.56 7.56 0 007.558-7.56 7.56 7.56 0 00-7.558-7.56z"
    />
    <path
      opacity={0.3}
      d="M142.991 119.98H80.639a7.558 7.558 0 00-7.558 7.559 7.56 7.56 0 007.558 7.56h62.352a7.56 7.56 0 007.558-7.56 7.558 7.558 0 00-7.558-7.559z"
    />
  </g>
  <path
    opacity={0.5}
    d="M214.295 337.522H113.421l5.907-265.525h89.06l5.907 265.525z"
    fill="url(#paint0_linear)"
  />
  <path
    d="M212.328 337.522h-96.834l5.666-265.525h85.497l5.671 265.525z"
    fill="#fff"
  />
  <path
    opacity={0.05}
    d="M138.64 337.522h-23.146l5.666-265.525h11.809l5.671 265.525z"
    fill="#000"
  />
  <path
    d="M100.849 237.359h126.595c0 4.762-1.891 9.329-5.258 12.697a17.949 17.949 0 01-12.694 5.259h-90.691a17.952 17.952 0 01-17.952-17.956zM100.849 148.08h126.595a17.954 17.954 0 01-17.952 17.955h-90.691a17.952 17.952 0 01-17.952-17.955z"
    fill="#6C63FF"
  />
  <path
    d="M164.144 133.181c12.784 0 23.146-10.364 23.146-23.15 0-12.786-10.362-23.15-23.146-23.15-12.783 0-23.145 10.364-23.145 23.15 0 12.786 10.362 23.15 23.145 23.15z"
    fill="#FFFFA5"
  />
  <path
    opacity={0.1}
    d="M210.436 255.31h-93.055v1.415h93.055v-1.415zM208.549 166.015h-89.748v.944h89.748v-.944z"
    fill="#000"
  />
  <path
    d="M189.654 337.522h-29.76v-40.87a14.881 14.881 0 0114.88-14.884 14.885 14.885 0 0114.88 14.884v40.87z"
    fill="#E0E0E0"
  />
  <path
    d="M443.311 355H.231s28.606-65.758 71.967-77.393a49.236 49.236 0 0119.207-1.36c10.458 1.33 19.918 6.816 26.638 14.943 14.213 17.187 57.74 54.639 144.828 11.841 0 0 31.176-31.182 81.247 5.668a96.075 96.075 0 009.535 4.116c15.362 5.808 46.091 13.899 67.937-3.173C451.818 286.02 443.311 355 443.311 355z"
    fill="#4D8AF0"
  />
  <g opacity={0.3} fill="#E0E0E0">
    <path
      opacity={0.3}
      d="M349.759 19.968h-62.352a7.559 7.559 0 00-7.558 7.56 7.559 7.559 0 007.558 7.56h62.352a7.559 7.559 0 007.558-7.56 7.559 7.559 0 00-7.558-7.56z"
    />
    <path
      opacity={0.3}
      d="M330.864 35.087h-62.352a7.559 7.559 0 00-7.558 7.56 7.559 7.559 0 007.558 7.56h62.352a7.559 7.559 0 007.558-7.56 7.559 7.559 0 00-7.558-7.56z"
    />
    <path
      opacity={0.3}
      d="M349.759 50.206h-62.352a7.559 7.559 0 00-7.558 7.56 7.559 7.559 0 007.558 7.56h62.352a7.559 7.559 0 007.558-7.56 7.559 7.559 0 00-7.558-7.56z"
    />
  </g>
  <g opacity={0.3} fill="#E0E0E0">
    <path
      opacity={0.3}
      d="M413.997 125.381h-62.352a7.558 7.558 0 00-7.558 7.559 7.559 7.559 0 007.558 7.56h62.352a7.559 7.559 0 007.558-7.56 7.558 7.558 0 00-7.558-7.559zM395.102 140.5H332.75a7.56 7.56 0 00-7.558 7.56 7.558 7.558 0 007.558 7.559h62.352a7.558 7.558 0 007.558-7.559 7.56 7.56 0 00-7.558-7.56zM413.997 155.619h-62.352a7.56 7.56 0 00-7.558 7.56 7.558 7.558 0 007.558 7.559h62.352a7.558 7.558 0 007.558-7.559 7.56 7.56 0 00-7.558-7.56z"
    />
  </g>
  <g opacity={0.3} fill="#030B44">
    <path opacity={0.3} d="M318.608 130.109h16.531s-6.228 11.024-16.531 0z" />
    <path
      opacity={0.3}
      d="M319.606 134.798l10.841-12.484s4.235 11.932-10.841 12.484z"
    />
  </g>
  <g opacity={0.3} fill="#030B44">
    <path opacity={0.3} d="M259.563 164.599h16.532s-6.228 11.024-16.532 0z" />
    <path
      opacity={0.3}
      d="M260.562 169.288l10.835-12.484s4.241 11.932-10.835 12.484z"
    />
  </g>
  <g opacity={0.3} fill="#030B44">
    <path opacity={0.3} d="M238.179 35.143h17.731s-6.68 11.846-17.731 0z" />
    <path
      opacity={0.3}
      d="M239.248 40.177l11.628-13.397s4.547 12.8-11.628 13.397z"
    />
  </g>
  <path
    opacity={0.05}
    d="M116.302 255.239c.502.046 1.029.071 1.551.071h18.067l-.382-17.951h-18.84l-.396 17.88zM134.595 148.065h-15.056l-.387 17.95h15.824l-.381-17.95z"
    fill="#000"
  />
  <path
    d="M469.272 230.99a.756.756 0 00.753-.753.754.754 0 00-1.285-.533.754.754 0 00.532 1.286z"
    fill="#4D8AF0"
  />
  <path
    d="M227.68 53.314c0 17.664-28.551 31.98-63.772 31.98-35.22 0-63.771-14.316-63.771-31.98S128.688 0 163.908 0c35.221 0 63.772 35.655 63.772 53.314z"
    fill="#6C63FF"
  />
  <path
    opacity={0.05}
    d="M99.766 53.314c0 17.664 28.551 31.98 63.771 31.98V0c-35.22 0-63.771 35.655-63.771 53.314z"
    fill="#000"
  />
  <defs>
    <linearGradient
      id="paint0_linear"
      x1={163.858}
      y1={337.522}
      x2={163.858}
      y2={71.9969}
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="gray" stopOpacity={0.25} />
      <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
      <stop offset={1} stopColor="gray" stopOpacity={0.1} />
    </linearGradient>
    <clipPath id="clip0" />
  </defs>
</svg>
);

export default LighthouseSVG;
