import React from "react";

const ArrowSVG = props => (
  <svg width={52} height={27} viewBox="0 0 52 27" fill="none" {...props}>
    <path stroke="#fff" d="M0.353553 0.646447L25.8094 26.1023" />
    <path stroke="#fff" d="M25.6464 26.1416L51.1023 0.68572" />
  </svg>
);

export default ArrowSVG;
