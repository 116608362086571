import React from "react"

const SquiglySeperator = props => (
  <svg width={168} height={940} viewBox="0 0 168 940" fill="none" {...props}>
    <path
      d="M0 133.836L82.413 0h84.686v940H0l82.413-106.019L0 700.67l82.413-147.482L0 396.259l82.413-156.404L0 133.836z"
      fill="#5C8484"
    />
  </svg>
)

export default SquiglySeperator
