import React from "react";

const FollowMeSVG = props => (
  <svg width={517} height={226} viewBox="0 0 517 226" fill="none" {...props}>
    <path fill="#4A3838" d="M82 87H101V152H82z" />
    <path fill="#604848" d="M51 87H91V152H51z" />
    <path transform="matrix(-1 0 0 1 102 0)" fill="#69AA7B" d="M0 0H50V29H0z" />
    <path transform="matrix(-1 0 0 1 71 58)" fill="#69AA7B" d="M0 0H50V29H0z" />
    <path transform="matrix(-1 0 0 1 74 0)" fill="#69AA7B" d="M0 0H32V29H0z" />
    <path
      transform="matrix(-1 0 0 1 119 29)"
      fill="#578E67"
      d="M0 0H35V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 139 58)"
      fill="#578E67"
      d="M0 0H35V29H0z"
    />
    <path transform="matrix(-1 0 0 1 69 29)" fill="#578E67" d="M0 0H35V29H0z" />
    <path
      transform="matrix(-1 0 0 1 101 29)"
      fill="#69AA7B"
      d="M0 0H50V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 118 58)"
      fill="#69AA7B"
      d="M0 0H50V29H0z"
    />
    <path transform="matrix(-1 0 0 1 102 0)" fill="#578E67" d="M0 0H28V26H0z" />
    <g filter="url(#filter0_f)">
      <path fill="#000" fillOpacity={0.25} d="M51 87H101V103H51z" />
    </g>
    <path fill="#52A96B" d="M0 151H208V190H0z" />
    <g filter="url(#filter1_f)">
      <path fill="#000" fillOpacity={0.2} d="M135 156H195V168H135z" />
    </g>
    <path fill="#4A3838" d="M171 100H190V165H171z" />
    <path fill="#604848" d="M140 100H180V165H140z" />
    <path
      transform="matrix(-1 0 0 1 191 13)"
      fill="#69AA7B"
      d="M0 0H50V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 160 71)"
      fill="#69AA7B"
      d="M0 0H50V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 163 13)"
      fill="#69AA7B"
      d="M0 0H32V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 208 42)"
      fill="#578E67"
      d="M0 0H35V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 228 71)"
      fill="#578E67"
      d="M0 0H35V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 158 42)"
      fill="#578E67"
      d="M0 0H35V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 190 42)"
      fill="#69AA7B"
      d="M0 0H50V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 207 71)"
      fill="#69AA7B"
      d="M0 0H50V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 191 13)"
      fill="#578E67"
      d="M0 0H28V26H0z"
    />
    <g filter="url(#filter2_f)">
      <path fill="#000" fillOpacity={0.25} d="M140 100H190V116H140z" />
    </g>
    <path fill="#4A3838" d="M241 90H260V155H241z" />
    <path fill="#604848" d="M210 90H250V155H210z" />
    <path transform="matrix(-1 0 0 1 261 3)" fill="#69AA7B" d="M0 0H50V29H0z" />
    <path
      transform="matrix(-1 0 0 1 230 61)"
      fill="#69AA7B"
      d="M0 0H50V29H0z"
    />
    <path transform="matrix(-1 0 0 1 233 3)" fill="#69AA7B" d="M0 0H32V29H0z" />
    <path
      transform="matrix(-1 0 0 1 278 32)"
      fill="#578E67"
      d="M0 0H35V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 298 61)"
      fill="#578E67"
      d="M0 0H35V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 228 32)"
      fill="#578E67"
      d="M0 0H35V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 260 32)"
      fill="#69AA7B"
      d="M0 0H50V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 277 61)"
      fill="#69AA7B"
      d="M0 0H50V29H0z"
    />
    <path transform="matrix(-1 0 0 1 261 3)" fill="#578E67" d="M0 0H28V26H0z" />
    <g filter="url(#filter3_f)">
      <path fill="#000" fillOpacity={0.25} d="M210 90H260V106H210z" />
    </g>
    <path fill="#52A96B" d="M0 180H205V219H0z" />
    <path fill="#52A96B" d="M205 162H479V201H205z" />
    <path fill="#4A3838" d="M312 87H331V152H312z" />
    <path fill="#604848" d="M281 87H321V152H281z" />
    <path transform="matrix(-1 0 0 1 332 0)" fill="#69AA7B" d="M0 0H50V29H0z" />
    <path
      transform="matrix(-1 0 0 1 301 58)"
      fill="#69AA7B"
      d="M0 0H50V29H0z"
    />
    <path transform="matrix(-1 0 0 1 304 0)" fill="#69AA7B" d="M0 0H32V29H0z" />
    <path
      transform="matrix(-1 0 0 1 349 29)"
      fill="#578E67"
      d="M0 0H35V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 369 58)"
      fill="#578E67"
      d="M0 0H35V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 299 29)"
      fill="#578E67"
      d="M0 0H35V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 331 29)"
      fill="#69AA7B"
      d="M0 0H50V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 348 58)"
      fill="#69AA7B"
      d="M0 0H50V29H0z"
    />
    <path transform="matrix(-1 0 0 1 332 0)" fill="#578E67" d="M0 0H28V26H0z" />
    <g filter="url(#filter4_f)">
      <path fill="#000" fillOpacity={0.25} d="M281 87H331V103H281z" />
    </g>
    <path fill="#4A3838" d="M123 97H142V162H123z" />
    <path fill="#604848" d="M92 97H132V162H92z" />
    <path
      transform="matrix(-1 0 0 1 143 10)"
      fill="#69AA7B"
      d="M0 0H50V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 112 68)"
      fill="#69AA7B"
      d="M0 0H50V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 115 10)"
      fill="#69AA7B"
      d="M0 0H32V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 160 39)"
      fill="#578E67"
      d="M0 0H35V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 180 68)"
      fill="#578E67"
      d="M0 0H35V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 110 39)"
      fill="#578E67"
      d="M0 0H35V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 142 39)"
      fill="#69AA7B"
      d="M0 0H50V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 159 68)"
      fill="#69AA7B"
      d="M0 0H50V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 143 10)"
      fill="#578E67"
      d="M0 0H28V26H0z"
    />
    <g filter="url(#filter5_f)">
      <path fill="#000" fillOpacity={0.25} d="M92 97H142V113H92z" />
    </g>
    <path fill="#4A3838" d="M460 95H479V160H460z" />
    <path fill="#604848" d="M429 95H469V160H429z" />
    <path transform="matrix(-1 0 0 1 480 8)" fill="#69AA7B" d="M0 0H50V29H0z" />
    <path
      transform="matrix(-1 0 0 1 449 66)"
      fill="#69AA7B"
      d="M0 0H50V29H0z"
    />
    <path transform="matrix(-1 0 0 1 452 8)" fill="#69AA7B" d="M0 0H32V29H0z" />
    <path
      transform="matrix(-1 0 0 1 497 37)"
      fill="#578E67"
      d="M0 0H35V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 517 66)"
      fill="#578E67"
      d="M0 0H35V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 447 37)"
      fill="#578E67"
      d="M0 0H35V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 479 37)"
      fill="#69AA7B"
      d="M0 0H50V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 496 66)"
      fill="#69AA7B"
      d="M0 0H50V29H0z"
    />
    <path transform="matrix(-1 0 0 1 480 8)" fill="#578E67" d="M0 0H28V26H0z" />
    <g filter="url(#filter6_f)">
      <path fill="#000" fillOpacity={0.25} d="M429 95H479V111H429z" />
    </g>
    <path fill="#52A96B" d="M208 151H479V167H208z" />
    <g filter="url(#filter7_f)">
      <path fill="#000" fillOpacity={0.2} d="M377 159H437V171H377z" />
    </g>
    <path fill="#4A3838" d="M413 100H432V165H413z" />
    <path fill="#604848" d="M382 100H422V165H382z" />
    <path
      transform="matrix(-1 0 0 1 433 13)"
      fill="#69AA7B"
      d="M0 0H50V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 402 71)"
      fill="#69AA7B"
      d="M0 0H50V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 405 13)"
      fill="#69AA7B"
      d="M0 0H32V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 450 42)"
      fill="#578E67"
      d="M0 0H35V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 470 71)"
      fill="#578E67"
      d="M0 0H35V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 400 42)"
      fill="#578E67"
      d="M0 0H35V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 432 42)"
      fill="#69AA7B"
      d="M0 0H50V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 449 71)"
      fill="#69AA7B"
      d="M0 0H50V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 433 13)"
      fill="#578E67"
      d="M0 0H28V26H0z"
    />
    <g filter="url(#filter8_f)">
      <path fill="#000" fillOpacity={0.25} d="M382 100H432V116H382z" />
    </g>
    <path fill="#6F5353" d="M141 189H208V219H141z" />
    <path fill="#6F5353" d="M208 189H311V219H208z" />
    <path fill="#6F5353" d="M298 189H401V219H298z" />
    <path fill="#6F5353" d="M388 189H479V219H388z" />
    <path fill="#6F5353" d="M76 189H143V219H76z" />
    <path fill="#6F5353" d="M52 170H155V200H52z" />
    <path fill="#100075" d="M228.454 119.989H256.8164V160H228.454z" />
    <path fill="#C68642" d="M219 81.6685H228.45414V119.9889H219z" />
    <path
      fill="#C68642"
      d="M256.816 81.6685H266.27013999999997V119.9889H256.816z"
    />
    <path d="M228.454 81.668h28.363v38.321h-28.363v-38.32z" fill="#04A07B" />
    <path fill="#04A07B" d="M219 81.6685H228.45414V93.5028H219z" />
    <path d="M256.816 81.668h9.455v11.835h-9.455V81.668z" fill="#04A07B" />
    <path fill="#292929" d="M228.454 150.983H256.8164V159.99957H228.454z" />
    <path fill="#C68642" d="M229.636 58H255.6349V81.6685H229.636z" />
    <path stroke="#151515" strokeWidth={0.5} d="M242.885 119.989L242.885 160" />
    <path fill="#000F35" d="M231.409 135.204H239.68138V138.02168H231.409z" />
    <path fill="#000F35" d="M245.59 135.204H253.86238V138.02168H245.59z" />
    <path
      fill="#F3F3F3"
      fillOpacity={0.8}
      d="M233.772 67.0166H239.68084V69.83427999999999H233.772z"
    />
    <path
      fill="#F3F3F3"
      fillOpacity={0.8}
      d="M245.59 67.0166H251.49884V69.83427999999999H245.59z"
    />
    <path
      fill="#3C00BC"
      d="M236.727 67.0166H239.68142V69.83427999999999H236.727z"
    />
    <path d="M245.59 67.017h2.954v2.817h-2.954v-2.817z" fill="#3C00BC" />
    <path
      fill="#4D2500"
      d="M235.545 77.7238H249.13529999999997V81.66855H235.545z"
    />
    <path
      fill="#4D2500"
      d="M235.545 73.779H239.09029999999998V77.72375H235.545z"
    />
    <path fill="#4D2500" d="M245.59 73.779H249.1353V77.72375H245.59z" />
    <path
      fill="#884100"
      fillOpacity={0.5}
      d="M239.09 70.9613H245.58972V73.77897999999999H239.09z"
    />
    <path fill="#4D2500" d="M229.636 58H255.6349V62.50829H229.636z" />
    <path fill="#4D2500" d="M229.636 62.5083H233.1813V65.88952H229.636z" />
    <path fill="#4D2500" d="M252.09 62.5083H255.6353V65.88952H252.09z" />
    <g filter="url(#filter9_f)">
      <path fill="#000" fillOpacity={0.2} d="M213 156H273V168H213z" />
    </g>
    <g filter="url(#filter10_f)">
      <path fill="#000" fillOpacity={0.2} d="M283 168H343V180H283z" />
    </g>
    <g filter="url(#filter11_f)">
      <path fill="#000" fillOpacity={0.2} d="M154 188H479V219H154z" />
    </g>
    <g filter="url(#filter12_f)">
      <path fill="#000" fillOpacity={0.2} d="M95 205H154V221H95z" />
    </g>
    <g filter="url(#filter13_f)">
      <path d="M95 170h59v15H95v-15z" fill="#000" fillOpacity={0.1} />
    </g>
    <g filter="url(#filter14_f)">
      <path d="M95 185h59v20H95v-20z" fill="#000" fillOpacity={0.15} />
    </g>
    <g filter="url(#filter15_f)">
      <path fill="#000" fillOpacity={0.2} d="M76 200H95V222H76z" />
    </g>
    <g filter="url(#filter16_f)">
      <path d="M51 171h44v12H51v-12z" fill="#000" fillOpacity={0.1} />
    </g>
    <g filter="url(#filter17_f)">
      <path d="M51 183h44v17H51v-17z" fill="#000" fillOpacity={0.15} />
    </g>
    <path fill="#4A3838" d="M377 114H396V179H377z" />
    <path fill="#604848" d="M346 114H386V179H346z" />
    <path
      transform="matrix(-1 0 0 1 397 27)"
      fill="#69AA7B"
      d="M0 0H50V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 366 85)"
      fill="#69AA7B"
      d="M0 0H50V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 369 27)"
      fill="#69AA7B"
      d="M0 0H32V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 414 56)"
      fill="#578E67"
      d="M0 0H35V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 434 85)"
      fill="#578E67"
      d="M0 0H35V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 364 56)"
      fill="#578E67"
      d="M0 0H35V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 396 56)"
      fill="#69AA7B"
      d="M0 0H50V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 413 85)"
      fill="#69AA7B"
      d="M0 0H50V29H0z"
    />
    <path
      transform="matrix(-1 0 0 1 397 27)"
      fill="#578E67"
      d="M0 0H28V26H0z"
    />
    <g filter="url(#filter18_f)">
      <path fill="#000" fillOpacity={0.25} d="M346 114H396V130H346z" />
    </g>
    <path fill="#634D4D" d="M283 148H344V169H283z" />
    <path stroke="#2F2F2F" d="M338.5 148L338.5 165" />
    <path stroke="#2F2F2F" d="M333.5 148L333.5 165" />
    <path stroke="#2F2F2F" d="M328.5 148L328.5 165" />
    <path stroke="#2F2F2F" d="M323.5 148L323.5 165" />
    <path stroke="#2F2F2F" d="M318.5 148L318.5 165" />
    <path stroke="#2F2F2F" d="M313.5 148L313.5 165" />
    <path stroke="#2F2F2F" d="M308.5 148L308.5 165" />
    <path stroke="#2F2F2F" d="M303.5 148L303.5 165" />
    <path stroke="#2F2F2F" d="M298.5 148L298.5 165" />
    <path stroke="#2F2F2F" d="M293.5 148L293.5 165" />
    <path stroke="#2F2F2F" d="M288.5 148L288.5 165" />
    <path fill="#7B6161" d="M283 156H344V177H283z" />
    <defs>
      <filter
        id="filter0_f"
        x={47}
        y={83}
        width={58}
        height={24}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation={2} result="effect1_foregroundBlur" />
      </filter>
      <filter
        id="filter1_f"
        x={131}
        y={152}
        width={68}
        height={20}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation={2} result="effect1_foregroundBlur" />
      </filter>
      <filter
        id="filter2_f"
        x={136}
        y={96}
        width={58}
        height={24}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation={2} result="effect1_foregroundBlur" />
      </filter>
      <filter
        id="filter3_f"
        x={206}
        y={86}
        width={58}
        height={24}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation={2} result="effect1_foregroundBlur" />
      </filter>
      <filter
        id="filter4_f"
        x={277}
        y={83}
        width={58}
        height={24}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation={2} result="effect1_foregroundBlur" />
      </filter>
      <filter
        id="filter5_f"
        x={88}
        y={93}
        width={58}
        height={24}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation={2} result="effect1_foregroundBlur" />
      </filter>
      <filter
        id="filter6_f"
        x={425}
        y={91}
        width={58}
        height={24}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation={2} result="effect1_foregroundBlur" />
      </filter>
      <filter
        id="filter7_f"
        x={373}
        y={155}
        width={68}
        height={20}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation={2} result="effect1_foregroundBlur" />
      </filter>
      <filter
        id="filter8_f"
        x={378}
        y={96}
        width={58}
        height={24}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation={2} result="effect1_foregroundBlur" />
      </filter>
      <filter
        id="filter9_f"
        x={209}
        y={152}
        width={68}
        height={20}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation={2} result="effect1_foregroundBlur" />
      </filter>
      <filter
        id="filter10_f"
        x={279}
        y={164}
        width={68}
        height={20}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation={2} result="effect1_foregroundBlur" />
      </filter>
      <filter
        id="filter11_f"
        x={150}
        y={184}
        width={333}
        height={39}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation={2} result="effect1_foregroundBlur" />
      </filter>
      <filter
        id="filter12_f"
        x={91}
        y={201}
        width={67}
        height={24}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation={2} result="effect1_foregroundBlur" />
      </filter>
      <filter
        id="filter13_f"
        x={91}
        y={166}
        width={67}
        height={23}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation={2} result="effect1_foregroundBlur" />
      </filter>
      <filter
        id="filter14_f"
        x={91}
        y={181}
        width={67}
        height={28}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation={2} result="effect1_foregroundBlur" />
      </filter>
      <filter
        id="filter15_f"
        x={72}
        y={196}
        width={27}
        height={30}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation={2} result="effect1_foregroundBlur" />
      </filter>
      <filter
        id="filter16_f"
        x={47}
        y={167}
        width={52}
        height={20}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation={2} result="effect1_foregroundBlur" />
      </filter>
      <filter
        id="filter17_f"
        x={47}
        y={179}
        width={52}
        height={25}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation={2} result="effect1_foregroundBlur" />
      </filter>
      <filter
        id="filter18_f"
        x={342}
        y={110}
        width={58}
        height={24}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation={2} result="effect1_foregroundBlur" />
      </filter>
    </defs>
  </svg>
);

export default FollowMeSVG;
